import { EventBus } from "./events/event_bus";
import { createSession, Session } from "./auth"
import { WebsocketClient } from "./realtime/websocket_client";
import { volieWebsocket, XhrClient, apiClient } from "./data";

// // Application context
export interface Context {
	config: Config  // application configuration
	session: Session | null, // authorization token
	bus: EventBus, // application event stream
    websocket: WebsocketClient, // realtime websocket connection
}

// Application configuration
export interface Config {
	authToken?: string // auth token
	disableWebsocketConnect?: boolean // disables websocket auto connect
    tokenHandler: TokenHandler // gets called when token has expired
}

export async function refreshToken(context: Context): Promise<any> {
    return new Promise(async (resolve, reject) => {
        context.config.tokenHandler(null, async (newToken) => {
            context.config.authToken = newToken
            try {
                await refreshSession(context)
                resolve();
            } catch (e) {
                throw e;
            }
        });
    })
}

export async function refreshSession(context: Context): Promise<any> {
    return new Promise(async (resolve, reject) => {
        var resp = await createSession(context);
        if (!resp.Data || !resp.Data.token) {
            context.session = null;
            reject();
            return;
        }
        context.session = {
            token: resp.Data.token,
        }
        resolve();
    })
}

export type TokenHandler = (oldToken: string, accept: (newToken: string) => void) => void;

export class App implements Context {
	config: Config  // application configuration
	session: Session | null // authorization token
	bus: EventBus // application event stream
    websocket: WebsocketClient // realtime websocket connection
    
    constructor(config: Config) {
        this.config = config;
        this.bus = new EventBus();
        // this.websocket = new WebsocketClient({
        //     Url: volieWebsocket,
        // });
        this.session = null;
    }

    async startSession(): Promise<any> {
        return new Promise(async (resolve) => {
            try {
                await refreshToken(this);
                resolve();
            } catch {
                throw new Error("Could not start session")
            }
        })
    }
}