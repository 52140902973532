import { Context } from "./app";
import { post, get, Response } from "./data";

export function getMe(ctx: Context): Promise<Response<any>> {
  return get<any>(ctx, '/app/api/me');
}

export interface Session {
  token: string;
}

export interface CapabilityTokenClaims {
  access_key: string
	organization_key: string
	user_email?: string
	user_first_name?: string;
	user_key?: string
  user_last_name?: string
	exp: number
  iat: number
}

export interface SessionTokenClaims {
  vchannel_session_id: number
	exp: number
  iat: number
}

export function parseJwt<T>(token: string): T {
  if (!token) { return null }
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload) as T;
};

export function capabilityToken(context: Context): CapabilityTokenClaims {
  return parseJwt<CapabilityTokenClaims>(context.config.authToken);
}

export function sessionToken(context: Context): SessionTokenClaims {
  if (context.session) {
    return parseJwt<SessionTokenClaims>(context.session.token)
  }
  return null;
}

export function createSession(ctx: Context): Promise<Response<Session>> {
  return post<Session>(ctx, '/vchannel/session', { token: ctx.config.authToken });
}

export function getSession(ctx: Context): Promise<Response<SessionTokenClaims>> {
  return get<SessionTokenClaims>(ctx, '/vchannel/session')
}
