import Vue from 'vue';
import Vuex from 'vuex';
import VueApp from './App.vue';
import { newStore } from './store';
import { App, Config } from '../../core/src/app';
import { Core } from '../../core/src/global';



Vue.config.productionTip = false;

export interface PluginOptions {
  id: string; // dom id to mount application
}

const UI = {
  async mount(plugin: PluginOptions, coreConfig: Config) {
    const core = new App(coreConfig)
    core.startSession()
    new Vue({
      store: new Vuex.Store(newStore(core)),
      render: h => h(VueApp)
    }).$mount(plugin.id);
  }
};

const w = window as any;
w.Volie = w.Volie || {} as any;
w.Volie.VChannel = w.Volie.VChannel || {}
w.Volie.VChannel.UI = UI;
w.Volie.VChannel.Core = Core;
